import React from "react";
import { AppFeature } from "./AppFeature";
import { Subheading } from "./catalyst/heading";
import { Divider } from "./catalyst/divider";

export const AppFeatures = () => {
  const featuresList = [
    // {
    //   title: "Update Your Profile",
    //   description:
    //     "Easily update your profile information and set your preferences. All changes will be reflected in the Payee App.",
    //   resourceUrl: "/profile",
    //   icon: "https://cdn.auth0.com/blog/hello-auth0/identity-providers-logo.svg",
    // },
    {
      title: "View your billing history",
      description: "View  your Payee subscription billing history.",
      resourceUrl: "/billing",
      icon: "https://cdn.auth0.com/blog/hello-auth0/mfa-logo.svg",
    },
    {
      title: "Manage Your Subscription",
      description:
        "View your current subscription status, and easily unsubscribe or subscribe.",
      resourceUrl: "/subscription",
      icon: "https://cdn.auth0.com/blog/hello-auth0/advanced-protection-logo.svg",
    },

    {
      title: "Access Support",
      description:
        "Need help? Access our support resources and get assistance with any issues or questions you may have.",
      resourceUrl: "/support",
      icon: "https://cdn.auth0.com/blog/hello-auth0/private-cloud-logo.svg",
    },
  ];

  return (
    <div className="auth0-features">
      <Subheading>Manage Your Account</Subheading>

      <Divider className="mt-4 mb-8" />

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {featuresList.map((feature) => (
          <AppFeature
            key={feature.resourceUrl}
            title={feature.title}
            description={feature.description}
            resourceUrl={feature.resourceUrl}
            icon={feature.icon}
          />
        ))}
      </div>
    </div>
  );
};
