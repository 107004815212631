import React from "react";
import BuyButtons from "../components/buttons/BuyButtons";

function Subscribe() {
  return (
    <div>
      <BuyButtons />
    </div>
  );
}

export default Subscribe;
