import React, { useEffect, useState } from "react";
import { AppFeatures } from "../components/AppFeatures";
import { PageLayout } from "../components/page-layout";
import { Heading } from "../components/catalyst/heading";
import { Divider } from "../components/catalyst/divider";
import { useAuth0 } from "@auth0/auth0-react";
import AppDownload from "../components/AppDownload";
import SubscribeUpsell from "../components/SubscribeUpsell";

export const HomePage = () => {
  const { user } = useAuth0();
  const [greeting, setGreeting] = useState("");

  

  useEffect(() => {
    const now = new Date();
    const hour = now.getHours();
    if (hour < 10) {
      setGreeting("Good morning");
    } else if (hour >= 10 && hour < 15) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);
  return (
    <PageLayout>
      <Heading className="text-4xl mb-8">
        {greeting}
        {user && user.name && `, ${user.name}`}
      </Heading>
      <AppFeatures />

      <div className="mt-8">
        <SubscribeUpsell />
        <Divider className="mt-4 mb-8" soft />
      </div>
      <AppDownload />
    </PageLayout>
  );
};
