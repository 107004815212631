import { useEffect, useRef, useState } from "react";
import {
  Pagination as UIPagination,
  PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from "./catalyst/pagination";

export default function Pagination({ currentPage, totalPages }) {
  const createPaginationArray = () => {
    let pages = [];
    let gapStart = false;
    let gapEnd = false;

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === 2 ||
        i === totalPages ||
        i === totalPages - 1 ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pages.push(i);
      } else if (i < currentPage - 1 && !gapStart) {
        pages.push("gap-start");
        gapStart = true;
      } else if (i > currentPage + 1 && !gapEnd) {
        pages.push("gap-end");
        gapEnd = true;
      }
    }

    return pages;
  };

  const pages = createPaginationArray();

  return (
    <UIPagination>
      {currentPage > 1 ? (
        <PaginationPrevious href={`?page=${currentPage - 1}`} />
      ) : (
        <PaginationPrevious />
      )}

      <PaginationList>
        {pages.map((page, index) => {
          if (page === "gap-start" || page === "gap-end") {
            return <PaginationGap key={index} />;
          } else {
            return (
              <PaginationPage
                key={index}
                href={`?page=${page}`}
                current={currentPage === page}
              >
                {page}
              </PaginationPage>
            );
          }
        })}
      </PaginationList>
      {currentPage < totalPages ? (
        <PaginationNext href={`?page=${currentPage + 1}`} />
      ) : (
        <PaginationNext />
      )}
    </UIPagination>
  );
}
