import React from "react";

function FailCross() {
  return (
    <div className="rounded-full w-20 h-20 bg-red-600 flex items-center justify-center">
      <svg
        fill="#fff"
        height="24px"
        width="24px"
        version="1.1"
        id="Capa_1"
        viewBox="0 0 490 490"
      >
        <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490   489.292,457.678 277.331,245.004 489.292,32.337 " />
      </svg>
    </div>
  );
}

export default FailCross;
