import React, { useState } from "react";
import { Avatar } from "../catalyst/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../catalyst/dropdown";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from "../catalyst/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "../catalyst/sidebar";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ShieldCheckIcon,
} from "@heroicons/react/16/solid";

import PayeeLogo from "../../assets/payee-logo1.png";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "../ui/Spinner";
import { useLocation } from "react-router-dom";

import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "../catalyst/alert";
import { Button } from "../catalyst/button";

const navItems = [
  { label: "Home", url: "/" },
  // { label: "Profile", url: "/profile" },
  { label: "Billing History", url: "/billing" },
  { label: "Subscription Management", url: "/subscription" },
  { label: "Support", url: "/support" },
];

export const NavBar = () => {
  const { user, isLoading, logout, loginWithRedirect } = useAuth0();

  let [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <Navbar className="mx-auto max-w-6xl">
      <img src={PayeeLogo} width={150} />
      <NavbarDivider className="max-lg:hidden" />

      <NavbarSection className="max-lg:hidden">
        {navItems.map(({ label, url }) => (
          <NavbarItem
            key={label}
            href={url}
            current={location.pathname === url}
          >
            {label}
          </NavbarItem>
        ))}
      </NavbarSection>
      <NavbarSpacer />
      <NavbarSection>
        {isLoading ? (
          <Spinner />
        ) : !user ? (
          <NavbarItem onClick={handleLogin}>Login</NavbarItem>
        ) : (
          <>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src={user.picture} square />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/account">
                  <ShieldCheckIcon />
                  <DropdownLabel>Account</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem onClick={() => setIsOpen(true)}>
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Alert open={isOpen} onClose={setIsOpen}>
              <AlertTitle>Are you sure you want to sign out ?</AlertTitle>
              <AlertActions>
                <Button plain onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setIsOpen(false);
                    handleLogout();
                  }}
                >
                  Sign Out
                </Button>
              </AlertActions>
            </Alert>
          </>
        )}
      </NavbarSection>
    </Navbar>
  );
};

export const MobileNavBar = () => {
  const { user } = useAuth0();

  return (
    <Sidebar>
      <SidebarHeader>
        <div>{!user ? <Spinner /> : <Avatar src={user.picture} square />}</div>
      </SidebarHeader>
      <SidebarBody>
        <SidebarSection>
          {navItems.map(({ label, url }) => (
            <SidebarItem key={label} href={url}>
              {label}
            </SidebarItem>
          ))}
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
};
