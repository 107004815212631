import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import axios from "axios";
import { Heading, Subheading } from "./catalyst/heading";
import { Text } from "./catalyst/text";
import BuyButtons from "./buttons/BuyButtons";

function SubscribeUpsell() {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const accessToken = await getAccessTokenSilently();
      setIsLoading(true);
      try {
        const { data, error } = await axios({
          url: `${process.env.REACT_APP_API_SERVER_URL}/api/user`,
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (error) {
          if (data && data.error) {
            console.error(data.error);
            throw new Error(data.error);
          } else {
            throw new Error(error);
          }
        }
        setIsSubscribed(
          data.auth0Data.isSubscribed ? data.auth0Data.isSubscribed : false
        );
      } catch (error) {
        if (error.response) {
          //Axios error
          if (error.response.status === 401) {
            // Bad credentials
            console.error("Bad credentials, something went wrong:", error);
            toast.error(
              "Your credentials are incorrect or your token has expired. Please try again."
            );
            await logout();
          } else if (error.response.data && error.response.data.message) {
            console.error(
              "Something went wrong:",
              error.response.data && error.response.data.message
                ? error.response.data.message
                : error
            );

            toast.error("Something went wrong");
          }
        } else {
          console.error("Something Went Wrong:", error);
          toast.error("User data could not be loaded.");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, [getAccessTokenSilently]);
  if (isSubscribed != null && isSubscribed === true) {
    return;
  }

  return (
    <div className=" py-12 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <Subheading>Subscribe And Start Saving</Subheading>
          <Heading>Subscribe And Start Saving!</Heading>
          <Text>
            Unlock exclusive discounts and benefits with our app. Download now
            from the Apple Store or Google Play Store and start saving today!
          </Text>
        </div>
        <div className="pt-8 flex items-center justify-center">
          <BuyButtons />
        </div>
      </div>
    </div>
  );
}

export default SubscribeUpsell;
