import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef } from "react";
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import Picture from "../components/ui/Picture";
import axios from "axios";
import Spinner from "../components/ui/Spinner";
import { Link, useNavigate } from "react-router-dom";

export const Profile = () => {
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const [formLoading, setFormLoading] = useState(false);

  const navigate = useNavigate();

  const [isSameData, setIsSameData] = useState(true);
  const [initialUserData, setInitialUserData] = useState({
    firstName: "",
    lastName: "",
    zipCode: "",
    avatarUrl: "",
  });

  const pictureInputRef = useRef(null);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    zipCode: "",
    avatarUrl: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setUserData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const validateImage = (file) => {
    const error = {};

    if (!file) {
      error.picture = "Please provide an image";
    }

    //Check file type:
    if (!file.type.toLowerCase().includes("image/")) {
      error.picture = "Please provide an image";
    }
    //Check file size less than 2mb:
    if (file.size / 1e6 > 2) {
      error.picture = "Image can not be over 2MB in size";
    }

    if (error.picture) {
      setErrors((prev) => ({
        ...prev,
        ...error,
      }));
      return false;
    }
    return true;
  };

  const handlePictureChange = async (e) => {
    setFormLoading(true);
    try {
      if (!validateImage(e.target.files[0])) return;

      const formData = new FormData();
      formData.append("picture", e.target.files[0]);

      const accessToken = await getAccessTokenSilently();

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/api/user/profile-picture`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (data.message) {
        data.success ? toast.success(data.message) : toast.error(data.message);
      }

      if (data.success) {
        // Update the UI with the new profile picture
        setUserData((prev) => ({
          ...prev,
          avatarUrl: data.data.avatarUrl,
        }));
        setInitialUserData((prev) => ({
          ...prev,
          avatarUrl: data.data.avatarUrl,
        }));
      }
    } catch (error) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
      } else {
        toast.error("Image could not be changed.");
      }

      if (pictureInputRef && pictureInputRef.current) {
        //Reset value:
        pictureInputRef.current.value = "";
      }
    } finally {
      setFormLoading(false);
    }
  };

  const validatePostCode = (postCode) => {
    // UK Post Code Regex
    const postCodeRegex = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i;
    return postCodeRegex.test(postCode);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, zipCode } = userData;

    // Validate inputs
    const newErrors = {};
    if (!firstName.trim()) newErrors.firstName = "First name is required";
    if (zipCode && !validatePostCode(zipCode))
      newErrors.zipCode = "Invalid UK post code";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setFormLoading(true);

    const accessToken = await getAccessTokenSilently();

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/api/user/update`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (data.message) {
        data.success ? toast.success(data.message) : toast.error(data.message);
      }

      if (data.data && data.success) {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to update profile");
      }
    } finally {
      setFormLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const accessToken = await getAccessTokenSilently();
      setFormLoading(true);
      try {
        const { data, error } = await axios({
          url: `${process.env.REACT_APP_API_SERVER_URL}/api/user`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (error) {
          if (data && data.error) {
            console.error(data.error);
            throw new Error(data.error);
          } else {
            throw new Error(error);
          }
        }

        setInitialUserData({
          firstName: data.userData.firstName,
          lastName: data.userData.lastName,
          zipCode: data.userData.zipCode,
          avatarUrl: data.userData.avatarUrl,
        });
        setUserData({
          firstName: data.userData.firstName,
          lastName: data.userData.lastName,
          zipCode: data.userData.zipCode,
          avatarUrl: data.userData.avatarUrl,
        });
      } catch (error) {
        if (error.response) {
          //Axios error
          if (error.response.status === 401) {
            // Bad credentials
            console.error("Bad credentials, something went wrong:", error);
            toast.error(
              "Your credentials are incorrect or your token has expired. Please try again."
            );
            await logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          } else if (error.response.data && error.response.data.message) {
            console.error(
              "Something went wrong:",
              error.response.data && error.response.data.message
                ? error.response.data.message
                : error
            );

            toast.error("Something went wrong");
          }
        } else {
          console.error("Something Went Wrong:", error);
          toast.error("User data could not be loaded.");
        }
      } finally {
        setFormLoading(false);
      }
    };
    fetchUserData();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    let isSame = true;
    Object.keys(userData).forEach((key) => {
      if (userData[key] !== initialUserData[key]) isSame = false;
    });
    setIsSameData(isSame);
  }, [userData, initialUserData]);

  if (!user) {
    return null;
  }

  return (
    <PageLayout>
      <div className="content-layout max-w-5xl m-auto">
        <form
          onSubmit={handleSubmit}
          className={`transition ${
            formLoading ? "pointer-events-none opacity-70" : "opacity-100"
          }`}
        >
          {formLoading && (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition delay-300 opacity-100 z-10">
              <Spinner />
            </div>
          )}

          <div className="space-y-12">
            <div className="border-b border-white/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-white">
                Profile
              </h2>
              <p className="mt-1 text-base leading-6 text-gray-400">
                These details help us ensure that all benefits you are shown are
                specifically tailored to your preferences.
              </p>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <label
                    htmlFor="picture"
                    className="block text-base font-medium leading-6 text-white"
                  >
                    Picture
                  </label>
                  <div className="mt-8 flex items-center gap-x-3">
                    <input
                      ref={pictureInputRef}
                      type="file"
                      accept="image/*"
                      name="picture"
                      id="picture"
                      className="hidden"
                      onChange={handlePictureChange}
                    />
                    <label
                      htmlFor="picture"
                      className="flex items-center gap-x-3"
                    >
                      {user && userData.avatarUrl ? (
                        <Picture
                          src={userData.avatarUrl}
                          loading={formLoading}
                        />
                      ) : (
                        <UserCircleIcon
                          className="h-40 w-40 text-gray-500"
                          aria-hidden="true"
                        />
                      )}
                    </label>
                    <button
                      type="button"
                      className="rounded-md bg-white/10 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-white/20"
                      onClick={(e) => pictureInputRef.current.click()}
                    >
                      Change
                    </button>

                    {errors.avatarUrl && (
                      <p className="text-red-500 absolute text-sm pt-2">
                        {errors.avatarUrl}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="firstName"
                    className="block text-base font-medium leading-6 text-white"
                  >
                    First Name
                  </label>

                  <div className="mt-2">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={userData.firstName}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6"
                    />
                  </div>
                  {errors.firstName && (
                    <p className="text-red-500 absolute text-sm pt-2">
                      {errors.firstName}
                    </p>
                  )}
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="lastName"
                    className="block text-base font-medium leading-6 text-white"
                  >
                    Last Name
                  </label>

                  <div className="mt-2">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={userData.lastName}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6"
                    />
                  </div>
                  {errors.lastName && (
                    <p className="text-red-500 absolute text-sm pt-2">
                      {errors.lastName}
                    </p>
                  )}
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="zipCode"
                    className="block text-base font-medium leading-6 text-white"
                  >
                    ZIP / Postal code
                  </label>

                  <div className="mt-2">
                    <input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      value={userData.zipCode}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6"
                    />
                  </div>
                  {errors.zipCode && (
                    <p className="text-red-500 absolute text-sm pt-2">
                      {errors.zipCode}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-2 mt-12">
            <h2 className="text-base font-semibold leading-7 text-white">
              Delete Account
            </h2>
            <p className="mt-1 text-base leading-6 text-gray-400 pb-4">
              Please make sure you'd like to proceed with this, as this action
              is not reversible
            </p>

            <Link
              to="/delete-account"
              className={`rounded-md cursor-pointer bg-red-500 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
            >
              Delete
            </Link>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            {!isSameData && (
              <button
                type="button"
                className="text-base font-semibold leading-6 text-white"
                onClick={(e) => setUserData(initialUserData)}
              >
                Cancel
              </button>
            )}

            <button
              type="submit"
              disabled={isSameData}
              className={`rounded-md cursor-pointer bg-indigo-500 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 ${
                isSameData ? "pointer-events-none opacity-50" : ""
              }`}
            >
              Save
            </button>
          </div>
        </form>

        {/* <div className="content__body">
          <p id="page-description">
            <span>
              You can use the <strong>ID Token</strong> to get the profile
              information of an authenticated user.
            </span>
            <span>
              <strong>Only authenticated users can access this page.</strong>
            </span>
          </p>
          <div className="profile-grid">
            <div className="profile__header">
              <img
                src={user.picture}
                alt="Profile"
                className="profile__avatar"
              />
              <div className="profile__headline">
                <h2 className="profile__title">{user.name}</h2>
                <span className="profile__description">{user.email}</span>
              </div>
            </div>
            <div className="profile__details">
              <CodeSnippet
                title="Decoded ID Token"
                code={JSON.stringify(user, null, 2)}
              />
            </div>
          </div>
        </div> */}
      </div>
    </PageLayout>
  );
};
