import { Heading, Subheading } from "../components/catalyst/heading";
import { PageLayout } from "../components/page-layout";

import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "../components/catalyst/alert";
import { Button } from "../components/catalyst/button";
import { useEffect, useState } from "react";
import Spinner from "../components/ui/Spinner";
import BuyButtons from "../components/buttons/BuyButtons";
import fetchSubscriptionStatus from "../lib/fetchSubscriptionStatus";
import unsubscribeUser from "../lib/unsubscribeUser";
import { useAuth0 } from "@auth0/auth0-react";
import { Text } from "../components/catalyst/text";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Subscription() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const token = await getAccessTokenSilently();
        const status = await fetchSubscriptionStatus(token);

        setIsSubscribed(status.isSubscribed);
      } catch (err) {
        setError("Failed to fetch subscription status.");
      } finally {
        setIsLoading(false);
      }
    };

    checkSubscriptionStatus();
  }, [getAccessTokenSilently]);

  // const handleUnsubscribe = async () => {
  //   try {
  //     setIsLoading(true);
  //     const token = await getAccessTokenSilently();

  //     await unsubscribeUser(token);
  //     toast.success("You have been unsubscribed");
  //     navigate("/");
  //   } catch (err) {
  //     setError("Failed to unsubscribe.");
  //   } finally {
  //     setIsLoading(false);
  //     setIsOpen(false);
  //   }
  // };

  return (
    <PageLayout>
      <div className="relative isolate overflow-hidden">
        <div className="px-6 py-12 sm:px-6 sm:py-16 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            {isLoading ? (
              <div className="flex items-center justify-center py-8">
                <Spinner />
              </div>
            ) : (
              <>
                <Subheading>Subscription Status</Subheading>
                {isSubscribed ? (
                  <>
                    <Subheading>You are subscribed to Payee!</Subheading>
                    {/* <div className="mt-10 flex items-center justify-center gap-x-6">
                      <Button type="button" onClick={() => setIsOpen(true)}>
                        Unsubscribe
                      </Button>
                      <Alert open={isOpen} onClose={() => setIsOpen(false)}>
                        <AlertTitle>
                          Are you sure you want to unsubscribe from Payee?
                        </AlertTitle>
                        <AlertDescription>
                          You will no longer be billed and will stop having
                          access to the benefits.
                        </AlertDescription>
                        <AlertActions>
                          <Button plain onClick={() => setIsOpen(false)}>
                            Cancel
                          </Button>
                          <Button onClick={handleUnsubscribe}>
                            Unsubscribe
                          </Button>
                        </AlertActions>
                      </Alert>
                    </div> */}
                  </>
                ) : (
                  <div>
                    <Heading>You're not subscribed yet</Heading>
                    <Text>
                      Subscribe now to enjoy exclusive discounts and benefits!
                    </Text>
                    <div className="py-4">
                      <BuyButtons />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Subscription;
