import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import Spinner from "../components/ui/Spinner";
import { useNavigate } from "react-router-dom";

function Logout() {
  const { logout, user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } else {
      //Navigate back to home
      navigate("/");
    }
  }, []);

  return (
    <PageLayout>
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    </PageLayout>
  );
}

export default Logout;
