import React from "react";

function SuccessTick() {
  return (
    <div className="rounded-full w-20 h-20 bg-green-600 flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        style={{ fill: "#fff" }}
      >
        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
      </svg>
    </div>
  );
}

export default SuccessTick;
