import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import SuccessTick from "../components/ui/SuccessTick";
import Spinner from "../components/ui/Spinner";
import { toast } from "react-toastify";
import axios from "axios";
import { Heading, Subheading } from "../components/catalyst/heading";
import { Divider } from "../components/catalyst/divider";
import { Text } from "../components/catalyst/text";
import { useSearchParams } from "react-router-dom";

function PaymentSuccesful() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState(false);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    let isMounted = true;

    async function fetchPaymentDetails() {
        try {
          const accessToken = await getAccessTokenSilently();
          const checkoutId = searchParams.get("checkout_id");
          if (!checkoutId) {
            setIsLoading(false);
            setIsSubscribed(false);
            toast.error("No Checkout id provided.");
            return;
          }

          const { data, error } = await axios({
            url: `${process.env.REACT_APP_API_SERVER_URL}/api/user/payment-details`,
            method: "POST",
            data: JSON.stringify({
              email: user.email,
              checkoutId,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!isMounted) {
            return;
          }

          if (error) {
            setIsLoading(false);
            setIsSubscribed(false);
            if (data.error) toast.error(data.error);
            else toast.error("Something went wrong");
            return;
          }

          if (data.found) {
            setIsLoading(false);

            if (data.isSubscribed) {
              setIsSubscribed(true);
              data.paymentDetails && setPaymentDetails(data.paymentDetails);
              toast.success("Subcription created.");
            }
          } else if (data.found === false) {
            //Retry after a second
            setTimeout(() => {
              fetchPaymentDetails();
            }, 2500);
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          setIsSubscribed(false);
          toast.error("Something went wrong");
        }
    }

    fetchPaymentDetails();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  return (
    <PageLayout>
      <div className="flex items-center justify-center h-full flex-col gap-8">
        {!isLoading && isSubscribed && <SuccessTick />}
        <h1 className="text-4xl text-center font-semibold text-white">
          {isLoading && !isSubscribed && "Verifying Payment Status"}
          {!isLoading && !isSubscribed && "Something went wrong"}
          {!isLoading && isSubscribed && "Payment Successful!"}
        </h1>

        {isLoading && !isSubscribed && <Spinner />}
        {paymentDetails && (
          <div className="p-6 rounded lg:min-w-[600px] max-w-[80vw]">
            <Heading>Payment Details</Heading>
            <Divider className="my-4" soft />
            <div className="flex flex-col gap-8 lg:gap-40 lg:flex-row">
              <div className="flex flex-col gap-8">
                <div>
                  <Text className="text-sm">Product Description:</Text>
                  <p className="font-semibold text-xl text-white">
                    {paymentDetails.nickname ?? "N/A"}
                  </p>
                </div>
                <div>
                  <Text className="text-sm">Subscription ID:</Text>
                  <p className="font-semibold text-xl text-white">
                    {paymentDetails.subscriptionId}
                  </p>
                </div>
                <div>
                  <Text className="text-sm">Subscription Created At:</Text>
                  <p className="font-semibold text-xl text-white">
                    {new Date(paymentDetails.createdAt * 1000).toDateString()}
                  </p>
                </div>
                <div className="flex justify-between gap-16">
                  <div>
                    <Text className="text-sm">Subscription Start:</Text>
                    <p className="font-semibold text-xl text-white">
                      {new Date(
                        paymentDetails.subscriptionStart * 1000
                      ).toDateString()}
                    </p>
                  </div>
                  <div>
                    <Text className="text-sm">Subscription End:</Text>
                    <p className="font-semibold text-xl text-white">
                      {new Date(
                        paymentDetails.subscriptionEnd * 1000
                      ).toDateString()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <Text className="text-sm">Amount Paid:</Text>
                <Subheading>{paymentDetails.amount}</Subheading>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default PaymentSuccesful;
