import React from "react";
import { NavBarBrand } from "./nav-bar-brand";
import { NavBarButtons } from "./nav-bar-buttons";
import { NavBarTabs } from "./nav-bar-tabs";

import { Avatar } from "../../catalyst/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../../catalyst/dropdown";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarLabel,
  NavbarSection,
  NavbarSpacer,
} from "../../catalyst/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "../../catalyst/sidebar";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ShieldCheckIcon,
} from "@heroicons/react/16/solid";
import PayeeLogo from "../../../assets/payee-logo1.png";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "../../ui/Spinner";

const navItems = [
  { label: "Home", url: "/" },
  // { label: "Profile", url: "/profile" },
  { label: "Billing History", url: "/billing" },
  { label: "Subscription Management", url: "/subscription" },
  { label: "Support", url: "/support" },
];

export const NavBar = () => {
  const { user } = useAuth0();

  return (
    <Navbar>
      <Avatar
        className="w-28 border-none rounded-none"
        square={true}
        src={PayeeLogo}
      />
      <NavbarDivider className="max-lg:hidden" />

      <NavbarSection className="max-lg:hidden">
        {navItems.map(({ label, url }) => (
          <NavbarItem key={label} href={url}>
            {label}
          </NavbarItem>
        ))}
      </NavbarSection>
      <NavbarSpacer />
      <NavbarSection>
        <Dropdown>
          <DropdownButton as={NavbarItem}>
            {!user ? <Spinner /> : <Avatar src={user.picture} square />}
          </DropdownButton>
          <DropdownMenu className="min-w-64" anchor="bottom end">
            <DropdownItem href="/account">
              <ShieldCheckIcon />
              <DropdownLabel>Account</DropdownLabel>
            </DropdownItem>
            <DropdownDivider />
            <DropdownItem href="/logout">
              <ArrowRightStartOnRectangleIcon />
              <DropdownLabel>Sign out</DropdownLabel>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </NavbarSection>
    </Navbar>
  );

  return (
    <div className="nav-bar__container">
      <nav className="nav-bar">
        <NavBarBrand />
        <NavBarTabs />
        <NavBarButtons />
      </nav>
    </div>
  );
};

export const MobileNavBar = () => {
  const { user } = useAuth0();

  return (
    <Sidebar>
      <SidebarHeader>
        <div>
          {!user ? <Spinner /> : <Avatar src={user.picture} square />}

          <SidebarLabel>Tailwind Labs</SidebarLabel>
          <ChevronDownIcon />
        </div>
      </SidebarHeader>
      <SidebarBody>
        <SidebarSection>
          {navItems.map(({ label, url }) => (
            <SidebarItem key={label} href={url}>
              {label}
            </SidebarItem>
          ))}
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );

  return (
    <div className="nav-bar__container">
      <nav className="nav-bar">
        <NavBarBrand />
        <NavBarTabs />
        <NavBarButtons />
      </nav>
    </div>
  );
};
