import React from "react";
import { Link } from "react-router-dom";

import { Heading, Subheading } from "./catalyst/heading";
import { Divider } from "./catalyst/divider";

export const AppFeature = ({ title, description, resourceUrl, icon }) => (
  <Link
    to={resourceUrl}
    className=" py-20 px-8 rounded-2xl shadow-2xl transition hover:scale-105 bg-zinc-800"
  >
    <Heading level={2} className="flex items-center gap-4">
      <img
        className="auth0-feature__icon"
        src={icon}
        alt="external link icon"
      />
      {title}
    </Heading>
    <Divider className="my-4" />

    <Subheading>{description}</Subheading>
  </Link>
);
