import React from "react";

function Picture({ src }) {
  return (
    <div className="w-40 h-40 rounded-full  border-4 border-gray-500 flex items-center justify-center overflow-hidden">
      <img
        src={src}
        alt="User"
        className="h-full object-cover"
        width={200}
        height={200}
      />
    </div>
  );
}

export default Picture;
