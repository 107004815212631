import React, { useState, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { Alert, AlertActions, AlertTitle } from "../components/catalyst/alert";
import { Button } from "../components/catalyst/button";
import { Divider } from "../components/catalyst/divider";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

function DeleteAccount() {
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  const [planDetails, setPlanDetails] = useState(null);
  const { getAccessTokenSilently, logout } = useAuth0();

  const fetchPaymentDetails = async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_SERVER_URL}/api/user/delete`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setPlanDetails(data.data);
    } catch (error) {
      toast.error("Unable to fetch your details. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  const handleDeleteNow = async () => {
    if (deleting) return;
    try {
      setDeleting(true);
      const accessToken = await getAccessTokenSilently();
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/api/user/delete`,
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast[data.success ? "success" : "error"](
        data.message || "Action failed."
      );
      if (data.success) {
        setIsDeleteModalOpen(false);
        setTimeout(() => {
          logout({ logoutParams: { returnTo: window.location.origin } });
        }, 500);
      }
    } catch (error) {
      toast.error("Failed to delete account. Please try again.");
    } finally {
      setDeleting(false);
    }
  };

  const handleInvoiceGeneration = async () => {
    if (deleting) return;
    try {
      setDeleting(true);
      const accessToken = await getAccessTokenSilently();
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/api/user/cancellation-invoice`,
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast[data.success ? "success" : "error"](
        data.message || "Action failed."
      );
      setIsInvoiceModalOpen(false);

      setPlanDetails((prev) => ({
        ...prev,
        ...data.data,
      }));
    } catch (error) {
      toast.error("Failed to send cancellation invoice. Please try again.");
    } finally {
      setDeleting(false);
    }
  };

  const formatDate = (date, showTime = true) =>
    moment.tz(date, "UTC").format(`DD MMM YYYY${showTime ? ", hh:mm a" : ""}`);

  const getRemainingTimeString = (details) => {
    const months = details.remainingMonths > 1 ? "months" : "month";
    const days = details.outlyingDays > 1 ? "days" : "day";
    return `${details.remainingMonths} ${months}${
      details.outlyingDays ? ` and ${details.outlyingDays} ${days}` : ""
    }`;
  };

  return (
    <PageLayout>
      <div className="p-6 max-w-lg mx-auto bg-[#0e0e0e] rounded-lg shadow-md">
        <h1 className="text-xl font-bold text-gray-200">Delete Account</h1>
        <p className="mt-4 text-gray-300">
          We're sad to see you go! If there's anything we can do to improve your
          experience, please let us know on our{" "}
          <Link to="/support" className="text-blue-400 underline">
            contact page
          </Link>{" "}
          and we will do everything to help.
        </p>

        {loading ? (
          <div className="flex items-center justify-center mt-6">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-500"></div>
          </div>
        ) : planDetails ? (
          <>
            <div className="mt-6">
              {planDetails.companyUser ? (
                <p className="text-red-300">
                  It appears you're part of{" "}
                  <strong>{planDetails.companyName}</strong>. Please contact
                  your company admin for account deletion.
                </p>
              ) : (
                planDetails.isSubscribed && (
                  <>
                    <Divider className="mb-6" />
                    <div className="flex gap-12 text-gray-300">
                      <p className=" w-40">Plan Type:</p>
                      <strong>{planDetails.planType}</strong>
                    </div>

                    <div className="flex gap-12 text-gray-300">
                      <p className=" w-40">Contract Started On: </p>
                      <strong>{formatDate(planDetails.planStart)} UTC</strong>
                    </div>

                    <div className="flex gap-12 text-gray-300">
                      <p className=" w-40">Contract Ends On: </p>

                      <strong>{formatDate(planDetails.planEnd)} UTC</strong>
                    </div>
                    {!planDetails.nextInstallement && (
                      <div className="flex gap-12 text-gray-300">
                        <p className=" w-40">Remaining Time: </p>
                        <strong>{getRemainingTimeString(planDetails)}</strong>
                      </div>
                    )}

                    {planDetails.nextInstallement && (
                      <div className="flex gap-12 text-gray-300">
                        <p className=" w-40">Next Payment On: </p>

                        <strong>
                          {formatDate(planDetails.nextInstallement, false)}
                        </strong>
                      </div>
                    )}
                    <Divider className="my-4" />
                    {planDetails.remainingTotal &&
                    planDetails.remainingTotal > 0 ? (
                      <p className="text-red-300">
                        As per your contract, you still have to pay for the
                        remaining {getRemainingTimeString(planDetails, false)}{" "}
                        and a cancellation fee of {planDetails.cancellationFee}
                        £, amounting to a total of{" "}
                        <strong>{planDetails.remainingTotal}£</strong>.
                        {planDetails.existingInvoice
                          ? `You have already been sent an invoice at your email for this
                          amount. Once paid, your account will be deleted
                          automatically. The invoice is only valid for 24 hours. Simply
                          ignore the invoice if you change your mind`
                          : planDetails.savedInvoice
                          ? `
                          You have been sent an invoice at your email for this
                          amount. Once paid, your account will be deleted
                          automatically. The invoice is only valid for 24 hours. Simply
                          ignore the invoice if you change your mind
                          `
                          : ""}
                      </p>
                    ) : (
                      planDetails.planType === "Yearly" && (
                        <p className="text-red-300">
                          You have paid for the entire year upfront, and
                          currently still have{" "}
                          {getRemainingTimeString(planDetails)} left. Deleting
                          your account now will mean you will no longer have
                          access to Payeee, even for the paid period. Are you
                          sure ?
                        </p>
                      )
                    )}
                  </>
                )
              )}
            </div>

            {planDetails.deletable && (
              <div className="mt-6">
                <button
                  onClick={() => setIsDeleteModalOpen(true)}
                  className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-all"
                >
                  Delete Account
                </button>
              </div>
            )}

            {planDetails.invoicable &&
              !planDetails.existingInvoice &&
              !planDetails.savedInvoice && (
                <div className="mt-6">
                  <button
                    onClick={() => setIsInvoiceModalOpen(true)}
                    className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-all"
                  >
                    Send Invoice
                  </button>
                </div>
              )}

            <Alert open={isDeleteModalOpen} onClose={setIsDeleteModalOpen}>
              <AlertTitle>Confirm Deletion</AlertTitle>
              <Divider className="my-4" />
              <p className="text-white">
                Please take a moment to make sure you'd like to proceed.
              </p>
              <p className="text-white my-2">
                This is the last step in your deletion process. This action is
                irreversible. All your data will be permanently deleted.
              </p>
              <AlertActions>
                <Button plain onClick={() => setIsDeleteModalOpen(false)}>
                  Cancel
                </Button>
                <Button
                  className="w-full bg-red-500  cursor-pointer hover:bg-red-600 text-white font-bold py-2 px-4 rounded disabled:opacity-60 disabled:pointer-events-none transition-all"
                  disabled={deleting}
                  onClick={() => {
                    handleDeleteNow();
                  }}
                >
                  {deleting && (
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-1"></div>
                  )}
                  Delete Now
                </Button>
              </AlertActions>
            </Alert>
            <Alert open={isInvoiceModalOpen} onClose={setIsInvoiceModalOpen}>
              <AlertTitle>Confirm Invoice Generation</AlertTitle>
              <Divider className="my-4" />
              <p className="text-white">
                Please take a moment to confirm before proceeding.
              </p>
              <p className="text-white my-2">
                Once you proceed, an invoice will be generated. Payment of this
                invoice will automatically delete all your data from Payeee.
              </p>
              <p className="text-white my-2 text-sm">
                The invoice will be sent to your email and will remain valid for
                24 hours.
              </p>
              <AlertActions>
                <Button plain onClick={() => setIsInvoiceModalOpen(false)}>
                  Cancel
                </Button>
                <Button
                  className="w-full bg-red-500  cursor-pointer hover:bg-red-600 text-white font-bold py-2 px-4 rounded disabled:opacity-60 disabled:pointer-events-none transition-all"
                  disabled={deleting}
                  onClick={() => {
                    handleInvoiceGeneration();
                  }}
                >
                  {deleting && (
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-1"></div>
                  )}
                  Send Invoice
                </Button>
              </AlertActions>
            </Alert>
          </>
        ) : (
          <p className="text-gray-300">
            We couldn't retrieve your account details. Please try again later.
          </p>
        )}
      </div>
    </PageLayout>
  );
}

export default DeleteAccount;
