import React from "react";

import { PageFooter } from "./page-footer";
import { StackedLayout } from "./catalyst/stacked-layout";

import { NavBar, MobileNavBar } from "./navigation/Navigation";

export const PageLayout = ({ children }) => {
  return (
    <StackedLayout navbar={<NavBar />} sidebar={<MobileNavBar />}>
      {children}
    </StackedLayout>

    // <div className="page-layout">
    //   <NavBar />
    //   {/* <MobileNavBar /> */}
    //   <div className="page-layout__content">{children}</div>
    //   <PageFooter />
    // </div>
  );
};
