import { useState } from "react";
import { Button } from "../components/catalyst/button";
import { Divider } from "../components/catalyst/divider";
import { Heading, Subheading } from "../components/catalyst/heading";
import { Input } from "../components/catalyst/input";
import { Select } from "../components/catalyst/select";
import { Text } from "../components/catalyst/text";
import { Textarea } from "../components/catalyst/textarea";
import { PageLayout } from "../components/page-layout";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/ui/Spinner";

export const metadata = {
  title: "Contact Us",
};

export default function ContactPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    supportType: "general_inquiry",
    subject: "",
    description: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.email ||
      !formData.subject ||
      !formData.description
    ) {
      toast.error("Please fill out all required fields.");
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/api/user/contact`,
        JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Your message has been sent successfully!");
      setTimeout(() => {
        window.location.href = "https://payeee.co.uk/";
      }, 500);
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout>
      <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-4xl">
        <Heading>Contact Us</Heading>
        <Text className="mt-2 text-gray-600">
          Use this form to report bugs, request features, or get in touch with
          our support team. We’ll do our best to get back to you as soon as
          possible.
        </Text>

        <Divider className="my-10 mt-6" />

        {/* Name Field */}
        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="space-y-1">
            <Subheading>Your Name</Subheading>
          </div>
          <div>
            <Input
              type="text"
              aria-label="Your Name"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
        </section>

        <Divider className="my-10" soft />

        {/* Email Field */}
        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="space-y-1">
            <Subheading>Your Email</Subheading>
          </div>
          <div>
            <Input
              type="email"
              aria-label="Your Email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        </section>

        <Divider className="my-10" soft />

        {/* Support Type Select */}
        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="space-y-1">
            <Subheading>How can we help you?</Subheading>
            <Text>Select the type of support you need:</Text>
          </div>
          <div>
            <Select
              aria-label="Support Type"
              name="supportType"
              value={formData.supportType}
              onChange={handleChange}
            >
              <option value="general_inquiry">General Inquiry</option>
              <option value="bug_report">Bug Report</option>
              <option value="feature_request">Feature Request</option>
              <option value="customer_support">Customer Support</option>
            </Select>
          </div>
        </section>

        <Divider className="my-10" soft />

        {/* Subject Field */}
        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="space-y-1">
            <Subheading>Subject</Subheading>
          </div>
          <div>
            <Input
              type="text"
              aria-label="Subject"
              name="subject"
              placeholder="Briefly describe your issue or request"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
        </section>

        <Divider className="my-10" soft />

        {/* Description Field */}
        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="space-y-1">
            <Subheading>Description</Subheading>
            <Text>Provide more details about your request or issue:</Text>
          </div>
          <div>
            <Textarea
              aria-label="Description"
              name="description"
              placeholder="Provide as much detail as possible"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </div>
        </section>

        <Divider className="my-10" soft />

        {/* Submit Button */}
        <div className="flex justify-end gap-4">
          <Button
            disabled={isLoading}
            className="cursor-pointer min-w-24"
            type="submit"
          >
            {isLoading ? (
              <Spinner width={"w-6"} height={"h-6"} border={"border-2"} />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </PageLayout>
  );
}
