import { PageLayout } from "../components/page-layout";
import FailCross from "../components/ui/FailCross";

function PaymentFailed() {
  return (
    <PageLayout>
      <div className="flex items-center justify-center h-full flex-col gap-8 max-w-[60vh] text-center m-auto">
        <FailCross />
        <h1 className="text-4xl text-center font-semibold text-white">
          Payment Failed
        </h1>
        <p className="text-xl font-semibold text-gray-100">
          Something went wrong during payment. Please use another payment method
          or try again later.
        </p>
      </div>
    </PageLayout>
  );
}

export default PaymentFailed;
