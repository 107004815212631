import "@stripe/stripe-js";
import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/Home";
import { NotFoundPage } from "./pages/not-found-page";
import { Profile } from "./pages/Profile";

import Subscribe from "./pages/subscribe";
import PaymentSuccesful from "./pages/PaymentSuccesful";
import PaymentFailed from "./pages/PaymentFailed";
import BillingHistory from "./pages/BillingHistory";
import Subscription from "./pages/Subscription";
import Support from "./pages/Support";
import DeleteAccount from "./pages/DeleteAccount";
import Logout from "./pages/Logout";

export const App = () => {
  const { isLoading, user } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<AuthenticationGuard component={HomePage} />} />
      <Route
        path="/subscribe"
        element={<AuthenticationGuard component={Subscribe} />}
      />
      {/* <Route
        path="/profile"
        element={<AuthenticationGuard component={Profile} />}
      /> */}

      <Route
        path="/payment-successful"
        element={<AuthenticationGuard component={PaymentSuccesful} />}
      />
      <Route path="/logout" element={Logout} />
      <Route
        path="/payment-failed"
        element={<AuthenticationGuard component={PaymentFailed} />}
      />
      <Route
        path="/billing"
        element={<AuthenticationGuard component={BillingHistory} />}
      />
      <Route
        path="/subscription"
        element={<AuthenticationGuard component={Subscription} />}
      />
      <Route path="/support" element={<Support />} />
      <Route
        path="/account"
        element={<AuthenticationGuard component={Profile} />}
      />
      <Route
        path="/delete-account"
        element={<AuthenticationGuard component={DeleteAccount} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
